<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="header-title">{{contract.name}}</h4>
          <p class="sub-header">Edit the form below and save. Use the badges below as variables</p>
          <form action="#" @submit.prevent="handleSubmit">
            <user-placeholders></user-placeholders>
            <div class="form-group">
              <quill-editor
                  ref="textArea"
                  style="min-height: 250px" :options="editorOption" v-model="content"
                            :class="{ 'is-invalid': submitted && $v.content.$error }"
              />
              <div v-if="submitted && !$v.content.error" class="invalid-feedback">This field is required.</div>
            </div>
            <div class="form-group text-center">
              <button type="submit" class="btn btn-primary">Save {{contract.name}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import appConfig from "../../../../../app.config.json";
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {required} from "vuelidate/lib/validators";
import UserPlaceholders from "../../../../components/UserPlaceholders";

/**
 * Starter component
 */
export default {
  validations: {
    content: {
      required
    }
  },
  head() {
    return {
      title: `${this.title}`,
    };
  },
  components: {
    UserPlaceholders
  },
  methods: {
    handleSubmit(){
      console.log('handle submit called')
      this.submitted = true
      // stop here if form is invalid
      this.$v.$touch()

      if(this.$v.$invalid)
        return;

      const slug = this.$route.params.slug;
      const content = this.content;
      this.$store.dispatch('updateContract', {
        slug,
        content
      }).then(() => {
        this.submitted = false
      })
    },
    appendVariable(value){
      // console.log('appendVariable clicked: ', value)

      let tArea = this.$refs.textArea;
      // let cursorPosition = tArea.selectionStart
      this.content = this.content + value;
    },

  },
  computed:{
    contract(){
      const contract = this.$store.getters.GET_CONTRACT;
      this.content = contract.content;
      return contract;
    },
  },
  watch: {
    '$route.params.slug': {
      handler(slug){
          // console.log('param: ' , newValue)
        this.$store.dispatch('getContract', {slug});
      },
      immediate: true
    }
  },
  data() {
    return {
      submitted: false,
      content: '',
      editorOption: {
        // Some Quill options...
        theme: "snow",
        modules: {
          toolbar: [
            [
              {
                size: [],
              },
            ],
            ["bold", "italic", "underline", "strike"],
            [
              {
                color: [],
              },
              {
                background: [],
              },
            ],
            [
              {
                script: "super",
              },
              {
                script: "sub",
              },
            ],
            [
              {
                header: [false, 1, 2, 3, 4, 5, 6],
              },
              "blockquote",
              "code-block",
            ],
            [
              {
                list: "ordered",
              },
              {
                list: "bullet",
              },
              {
                indent: "-1",
              },
              {
                indent: "+1",
              },
            ],
            [
              "direction",
              {
                align: [],
              },
            ],
            ["link"],
            ["clean"],
          ],
        },
      },
      option: {
        theme: "bubble",
        modules: {
          toolbar: [
            ["bold", "italic", "link"],
            [
              {
                header: 1,
              },
              {
                header: 2,
              },
              "blockquote",
            ],
          ],
        },
      },
    };
  },
};
</script>
